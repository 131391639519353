import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "../menu/AccountMenu";

function ButtonAppBar(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style={{ background: "rgba(34, 0, 76)" }} position="static">
        <Toolbar>
          <img 
            style={{ width: 100, height: 25 }}
            src="/assets/media/logo/sanofi_logo.png"
            alt="logo"
          />
          <Typography
            align="left"
            variant="h6"
            component="div"
            sx={{ margin: "20px 15px 15px 8px", flexGrow: 1 }}
          >
            For Sites
          </Typography>

          <Menu id={props.id} />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default ButtonAppBar;
