import { React, useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./LoginPage.css";
import Backdrop from "@mui/material/Backdrop";

function LoginPage() {
  const [open, setOpen] = useState(false);
  const spinnerProgressClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.location.replace(
      process.env.REACT_APP_EXOSTAR_LOGIN_URL
      /*"https://federation.exostartest.com/idp/startSSO.ping?PartnerSpId=test.sanofiforsites-s4s.com"*/
    );
  }, []);

  return (
    <div className="inner-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={spinnerProgressClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default LoginPage;
