import axios from "axios";

function CardServices(cardObj, count) {
  const token = Buffer.from(
   `${process.env.REACT_APP_GAMS_USERNAME}:${process.env.REACT_APP_GAMS_PASSWORD}`,
    "utf8"
  ).toString("base64");
  let data = JSON.stringify({
    log_type: "APP_ACCESS",
    exostar_id: cardObj.empId,
    vendor_name: cardObj.image,
    log_message: "Access to " + cardObj.url,
  });

  let config = {
    method: "POST",
    url: process.env.REACT_APP_GAMS_API_URL + process.env.REACT_APP_GAMS_API_PATH_SET,
    headers: {
      Authorization: `Basic ${token}`,
      "Content-Type": "application/json"
    },
    data: data,
  };

  axios.defaults.withCredentials = true;

  axios(config)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export default CardServices;
