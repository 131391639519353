import React from "react";
import { Navigate , useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonAppBar from "../components/App_bar/ButtonAppBar";
import BasicCard from "../components/cards/AppCard";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { makeStyles } from "@mui/styles";
import "./HomePage.css";
import CustomizedDialogs from "../components/contact_dialog/CustomizedDialogs";
import CustomAlertDialog from "../components/custom_dialog/customAlertDialog";

const useStyles = makeStyles({
  root: {
    "& .MuiAlert-icon": {
      color: "#735A8F !important;",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function reduceStudyId(data) {
  const studyTabs = data.reduce((studyObj, ele, { _ }) => {
    if (!studyObj[ele[1]]) studyObj[ele[1]] = [];
    studyObj[ele[1]].push(ele);
    return studyObj;
  }, {});

  Object.keys(studyTabs).forEach((keyName, keyIndex) => {
    const numArray = studyTabs[keyName];
    numArray.sort((a, b) => {
      return b[6] - a[6];
    });
  });
  return studyTabs;
}

function HomePage(props) {
  const { state } = useLocation();
  const errorFlag = (state?.results[0].data.length === 0) ? true : false;
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (state == null && !props.Authorized) return <Navigate to="/login" />;

  const reducedObj = reduceStudyId(state?.results[0].data);

  return (
    <div>
      <ButtonAppBar id={state.id} showAvatar={true}/>
      <Box>
        <Box sx={{ fontWeight: "normal", bgcolor: "background.paper" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {Object.keys(reducedObj).map((keyName, keyIndex) => {
              return (
                <Tab
                  sx={{ textTransform: "none", fontWeight: "bold" }}
                  key={keyIndex}
                  label={keyName}
                  {...a11yProps(keyIndex)}
                />
              );
            })}
          </Tabs>
        </Box>
        <Stack spacing={2} m={4}>
          <Collapse in={open}>
            <Alert
              className={classes.root}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ background: "#E6EDF9" }}
              severity="info"
            >
              <Typography color="#735A8F">
              Welcome to Sanofi for sites, your easiest way to access all your Sanofi's applications.<br/>
              Applications with a key icon are accessible through single sign-on with your Exostar account.
              </Typography>
            </Alert>
          </Collapse>
        </Stack>

        {Object.keys(reducedObj).map((keyName, keyIndex) => {
          return (
            <TabPanel key={keyIndex} value={value} index={keyIndex}>
              {reducedObj[keyName].map((element, indexCard) => {
                return (
                  <BasicCard
                    key={indexCard}
                    empId={element[0]}
                    title={element[2]}
                    image={element[3]}
                    url={element[4]}
                    lock={element[5]}
                  />
                );
              })}
            </TabPanel>
          );
        })}
      </Box>
      <div className="good_logo">
        <img src="/assets/media/logo/good_logo.png" alt="logo" />
        <CustomizedDialogs/>
      </div>
      <CustomAlertDialog ErrorFlagDialog={errorFlag} ></CustomAlertDialog>
    </div>
  );
}

export default HomePage;