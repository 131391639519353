import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import contacts from "./contacts.json";
import Avatar from "@mui/material/Avatar";
import EmailIcon from "@mui/icons-material/Email";
import "./contact.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const copy = async (data) => {
    await navigator.clipboard.writeText(data);
    alert("Copied");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link onClick={handleClickOpen} sx={{ m: "10px" }} underline="none">
        <HelpOutlineIcon sx={{ fontSize: "17px" }} /> {"Contact Support"}
      </Link>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Contact Support
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography component={"span"} variant={"body2"} gutterBottom>
            <Box
              sx={{
                width: "100%",
                height: 400,
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
            >
              <List>
                {contacts.contact.map((data, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      type="text"
                      onClick={() => copy(data.number)}
                    >
                      <ListItemIcon>
                        <ListItemAvatar>
                          <Avatar
                            variant="square"
                            alt={data.country}
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data.code}.svg`}
                          />
                        </ListItemAvatar>
                      </ListItemIcon>
                      <ListItemText primary={data.country} />
                      <ListItemIcon>
                        <ListItemText  sx={{color:"#735A8F"}} primary={data.number} />
                      </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions>
          <ListItemButton onClick={() => copy("Sanofi_Services@Stefanini.com")}>
            <ListItemIcon>
              <EmailIcon sx={{ fontSize: 44 }} />
            </ListItemIcon>
            <ListItemText primary="Sanofi_Services@Stefanini.com"  onClick={() => window.location = 'mailto:Sanofi_Services@Stefanini.com'}/>
          </ListItemButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
