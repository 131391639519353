import React  from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginForm from "./login/LoginPage";
import HomePage from "./home/HomePage";
import VerifiedPage from "./verified/VerifiedPage";
import PageNotFound from "./page_error/pageNotFound";
import './fonts/arial/arial.ttf'; 
import './fonts/arial/arialbd.ttf';

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<LoginForm />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/verified/:id" element={<VerifiedPage/>} />
      <Route path="/home" element={<HomePage Authorized={false}/>} />
      <Route path="/:pageName" element={<PageNotFound />} />
    </Routes>
  </Router>,
  document.getElementById("root")
);
