import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import "./AppCard.css";
import CardServices from "../../services/cardServices";

function AppCard(props) {
  const opacity = props.lock === 0 ? 0.2 : 1;
  const [counter, setCounter] = React.useState(1);
  const cardImage = props.image.replace(/ /g, "");

  const cardClickCounter = (cardObj) => {
    setCounter(counter + 1);
    CardServices(cardObj, counter);
  };

  const openCardLink = (cardObj) => {
    cardClickCounter(cardObj);
    window.open(cardObj.url, "_blank"); //to open new tab
  };

  return (
    <Card
      className="card_main"
      onClick={(e) => openCardLink(props)}
      sx={{
        display: "inline-block",
        margin: "15px",
        minWidth: 200,
        minHeight: 200,
        maxWidth: 200,
        maxHeight: 200,
        cursor: "pointer",
      }}
    >
      <CardContent className="card_content">
        <CardMedia
          sx={{ float: "right", opacity }}
          className="sso_icon_card"
          component="img"
          image={`/assets/media/logo/sso_icon.png`}
          alt="sso icon"
        />
        <CardMedia
          className="card_image"
          component="img"
          image={`/assets/media/images/card/${cardImage}.png`}
          alt="Vendor image"
        />
      </CardContent>
      <Typography className="card_title" variant="h9" component="div">
        {props.title}
      </Typography>
    </Card>
  );
}

export default AppCard;
