import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import "./AccountMenu.css";

export default function AccountMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{ bgcolor: "whitesmoke", color: "rgba(82,92,163,.95)" }}
            >
              {props.id.firstname.charAt(0)}
              {props.id.lastname.charAt(0)}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 18,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
          }}
        >
          <Avatar
            className="avatar-menu"
            sx={{ bgcolor: "rgba(34, 0, 76)", color: "white" }}
          >
            {props.id.firstname.charAt(0)}
            {props.id.lastname.charAt(0)}
          </Avatar>
        </MenuItem>

        <MenuItem
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "0",
            paddingTop: "0",
            pointerEvents: "none",
          }}
        >
          {" "}
          {props.id.firstname} {props.id.lastname}
        </MenuItem>
        <MenuItem
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "0",
            pointerEvents: "none",
          }}
        >
          {" "}
          {props.id.emailaddress}
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() =>
            (window.location = 
                process.env.REACT_APP_EXOSTAR_USER_URL
            /*  "https://secureaccess.exostartest.com/credmgr/pages/myAccount/ShowUserDetail.faces"*/
            )
          }
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Account Settings
        </MenuItem>
        <MenuItem
          onClick={() =>
            (window.location =
              process.env.REACT_APP_EXOSTAR_LOGOUT_URL
            /*  "https://secureaccess.exostartest.com/credmgr/accesslogin/Logout.do"*/
            )
          }
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
