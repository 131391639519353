import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./pageNotFound.css";

function PageNotFound() {
  const params = useParams();
  let navigate = useNavigate();

  function backToHome() {
    navigate("/home");
  }

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h3>Oops! Page not found</h3>
          <h1>
            <span>4</span>
            <span>0</span>
            <span>4</span>
          </h1>
        </div>
        <h2>
          we are sorry, but the Page <b>"{params.pageName}"</b> you requested was not found
        </h2>
        <Button variant="contained" onClick={backToHome}>
          Back to Home
        </Button>
      </div>
    </div>
  );
}

export default PageNotFound;