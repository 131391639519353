import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import LoginServices from "../services/loginServices";
import Backdrop from "@mui/material/Backdrop";

function VerifiedPage(props) {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const token = Buffer.from(`${process.env.REACT_APP_GAMS_USERNAME}:${process.env.REACT_APP_GAMS_PASSWORD}`, "utf8").toString(
    "base64"
  );

  let { id } = useParams();
  var identityObj = JSON.parse(id);
   console.log(identityObj);
  const Login = () => {
    localStorage.setItem("user-details", token);
    const data = LoginServices(identityObj);
    spinnerProgressOpen();
    data.then(function (logData) {
      logData.id = identityObj;
      spinnerProgressClose();
      navigate("/home", { state: logData });
    });
  };

  const spinnerProgressClose = () => {
    setOpen(false);
  };

  const spinnerProgressOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    Login();
  }, []);

  return (
    <div className="inner-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={spinnerProgressClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default VerifiedPage;