import axios from "axios";

async function LoginServices(identityObj) {
  try {
    let data = JSON.stringify({
      exostar_id: identityObj.nameId, // UAT
      //exostar_id: "I0360706", // DEV
    });

    let config = {
      method: "POST",
      url: process.env.REACT_APP_GAMS_API_URL + process.env.REACT_APP_GAMS_API_PATH_GET,
      headers: {
        Authorization:
          `Basic ` +
          Buffer.from(
            process.env.REACT_APP_GAMS_USERNAME + `:` + process.env.REACT_APP_GAMS_PASSWORD,
            "utf8"
          ).toString("base64"),
        "Content-Type": "application/json"
      },
      data: data,
    };
    axios.defaults.withCredentials = true;
    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export default LoginServices;
